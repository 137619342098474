<script setup lang="ts">
import dayjs from "dayjs";

import type { Promotion } from "@/types";

const { t } = useT();

const props = defineProps<{
	type?: Promotion["type"];
	isFull?: boolean;
	isAvailable?: boolean;
	complete?: number;
	goal?: number;
	image: string;
	minPromoCost?: string;
	title: string;
	timerDate?: string;
}>();

const emit = defineEmits<{ (event: "info"): void; (event: "onTimerStop"): void; (event: "chooseOffer"): void }>();

const buttonContent = computed(() =>
	props.isAvailable ? t("Collect Now for $", { minPromoCost: props.minPromoCost }) : t("Feed piggy")
);
const subTitleContent = computed(() =>
	props.isFull ? t("It's full - you can collect free SC!") : t("Get extra coins for every play!")
);
const titleContent = computed(() => (props.isFull ? t("Break Your Piggy Bank!") : props.title));

const handleHowItWorksModal = () => {
	emit("info");
};

const handleChooseOffer = () => {
	if (props.isAvailable && props.timerDate) {
		return;
	}
	emit("chooseOffer");
};

const { durationLeft, reset } = useCountdown({
	timestamp: props.timerDate ?? "",
	formatToken:
		dayjs(props.timerDate ?? "").diff(dayjs(), "d") >= 1 ? "DD[ d ] HH[ h ]mm[ m ]" : "HH[ h ]mm[ m ]ss[ s ]",
	isOptimized: true,
	onCountdownStop: () => {
		emit("onTimerStop");
	}
});

watch(
	() => props.timerDate,
	(value) => {
		if (value) {
			reset(value);
		}
	}
);
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 106,
			height: 112,
			loading: 'lazy',
			src: image
		}"
		width="328px"
		height="325px"
		class="card-piggy-bank"
		@click="handleChooseOffer"
	>
		<template #default>
			<span class="btn-info" @click.stop="handleHowItWorksModal">
				<ASvg name="16/info" class="icon" />
			</span>
		</template>

		<template #top>
			<AText type="small" :modifiers="['bold', 'uppercase']">
				{{ titleContent }}
			</AText>
		</template>
		<template #bottom>
			<AText type="small" :modifiers="['center', 'uppercase', 'bold']" as="div">{{ subTitleContent }}</AText>
			<MPrizeFund v-if="isFull" variant="entries" icon="28/secret-coin" :icon-size="24">
				<AText type="h4" as="h4">
					{{ numberFormat(goal ?? 0) }}
				</AText>
				<AText type="xx-small-secondary" :modifiers="['uppercase']">
					<i18n-t keypath="Free {br} secret coins">
						<template #br>
							<br />
						</template>
					</i18n-t>
				</AText>
			</MPrizeFund>
			<MPiggybankProgressBar v-else class="progress" :complete="complete" :goal="goal" />
			<AButton
				variant="primary"
				size="s"
				class="promo"
				data-tid="promo-piggy"
				:modifiers="['wide', isAvailable && timerDate ? 'disabled' : '']"
			>
				{{ buttonContent }}
			</AButton>
			<div v-if="!isFull || timerDate" class="foot">
				<AText v-if="timerDate" :size="14" class="color-gray-50">
					<i18n-t keypath="Break in {time}">
						<template #time>
							<AText :size="14" :modifiers="['bold']" class="color-gray-50">{{ durationLeft }}</AText>
						</template>
					</i18n-t>
				</AText>
				<AText v-else-if="!isFull" type="xx-small" :modifiers="['center', 'uppercase', 'nowrap']" as="div">{{
					t("You can break piggy at any time")
				}}</AText>
			</div>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card {
	color: var(--neutral);
	background: var(--gradient-7);
}

.progress {
	width: 100%;

	&:deep(.progress-wrapper) {
		background: var(--tertiary-800);
	}
}
</style>
